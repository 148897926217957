import { dynamicsObject } from "@/interfaces";
import { Modal } from "@/interfaces/modal/modal.dto";

export const user = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: "user-replenishment",
      data: {
        amount: "",
        type: "",
      },
      inputs: [
        {
          required: true,
          name: "",
          placeholder: "",
          id: "amount",
          grid: "1 / 13",
          type: "replenishment",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
      ],
    },
    {
      id: "botpay-withdrawal",
      data: {
        name: "",
        phone: "",
        bank: "",
        amount: "",
        botpay_balance: false,
        static:
          "После каждого вывода средств НА КАРТУ происходит блокировка вывода на 7 дней! \n\n Если вывод не поступил в течении 24ч., напишите об этом @evgeniy_konkin\n\nКомиссия за вывод (до 5 000 рублей) - 50 рублей",
      },
      inputs: [
        {
          required: true,
          name: "Сумма вывода *",
          placeholder: "Введите сумму вывода",
          id: "amount",
          grid: "1 / 13",
          type: "text",
          mask: "#######",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
        {
          required: true,
          name: "ФИО получателя *",
          placeholder: "Введите ФИО получателя",
          id: "name",
          grid: "1 / 13",
          type: "text",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
        {
          required: true,
          name: "Номер телефона получателя *",
          placeholder: "Номер телефона должен быть подключен к СБП",
          id: "phone",
          grid: "1 / 13",
          type: "text",
          mask: "+7(###)###-##-##",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
        {
          required: true,
          name: "Банк получателя *",
          placeholder: `Например, "Т-Банк"`,
          id: "bank",
          grid: "1 / 13",
          type: "text",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
        {
          required: false,
          name: "",
          placeholder: "Вывод средств на основной баланс",
          id: "botpay_balance",
          grid: "1 / 13",
          type: "switcher",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
        {
          required: true,
          name: "",
          placeholder: "",
          id: "static",
          grid: "1 / 13",
          type: "static",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
      ],
    },
    {
      id: "user-partner-withdrawal",
      data: {
        amount: "",
      },
      inputs: [
        {
          required: true,
          name: "Сумма вывода *",
          placeholder: "Минимальная сумма вывода - 500 руб.",
          id: "amount",
          grid: "1 / 13",
          type: "text",
          mask: "#######",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
      ],
    },
    {
      id: "admin-delete",
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: [],
    },
    {
      id: "instruction",
      data: {
        payment: data?.payment,
      },
      inputs: [
        {
          required: true,
          name: "",
          placeholder: "",
          id: "video",
          grid: "1 / 13",
          type: "video",
          drop: false,
          showDrop: false,
          drop_data: [],
          show: true,
        },
      ],
    },
  ];
};
